.base {
  overflow-wrap: anywhere;
  line-height: 19px;
  @apply p-4 focus:outline-none cursor-pointer rounded-full w-full font-semibold;
}

.primary {
  background: transparent linear-gradient(254deg, #3e60bb 0%, #324e98 100%) 0 0
    no-repeat padding-box;

  @apply font-sans text-white;
}

.secondary {
  box-shadow: 0 3px 16px #00297b29;

  @apply border-primary border text-primary;
}

.underlined {
  @apply underline text-primary;
}

.disabled {
  opacity: 0.5;
}
