.container {
  @apply flex flex-col mb-4 mt-4 w-full;
}

.formContainer {
  @apply flex flex-col items-center pt-4 px-2 w-full;
}

.buttonsContainer {
  @apply flex flex-col items-center p-2 w-full;
}

.text {
  @apply my-2 text-center font-semibold leading-tight;
}
