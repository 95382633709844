.outerContainer {
  @apply flex flex-grow items-center justify-center px-8 max-w-lg h-full text-center;
}

.container {
  @apply flex flex-col items-center max-w-lg break-words;
}

.textContainer {
  overflow-wrap: anywhere;

  @apply mb-8;
}

.textTitle {
  @apply mb-4 font-bold;
}

.textContent {
  line-height: 19px;

  @apply text-lg;
}
