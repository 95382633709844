.backgroundContainer {
  background: transparent url('assets/header.svg') 0 0 no-repeat border-box;
  background-size: 100% 76px;
  height: 76px;
  filter: drop-shadow(0 4px 10px rgba(0, 0, 0, 0.15));
  min-width: 320px;

  @apply fixed z-10 top-0 flex flex-grow w-full max-w-lg;
}

.noBackground {
  @apply w-screen bg-white z-10 fixed top-0 h-20;
}

.iconsContainer {
  min-width: 320px;
  height: 76px;

  @apply fixed z-20 top-0 flex flex-grow items-center justify-between px-8 w-full max-w-lg;
}

.hideIcon {
  @apply invisible;
}

.icon {
  @apply cursor-pointer;
}
