.form {
  @apply flex flex-col items-center mx-6;
}

.inputContainer {
  @apply flex flex-col flex-grow items-center mb-8 w-full max-w-md;
}

.infoContainer {
  @apply flex justify-center my-4 w-full h-12;
}
