.container {
  @apply relative flex flex-col w-full;
}

.inputContainer {
  @apply flex flex-row items-center mb-4 mt-2 bg-white border border-transparent rounded-full shadow-custom;
}

.label {
  line-height: 19px;

  @apply text-primary text-md font-medium;
}

.input {
  line-height: 19px;

  @apply flex-1 p-4 text-left text-lg bg-transparent focus:outline-none cursor-auto overflow-hidden;
}

.input:-webkit-autofill {
  transition: background-color 5000s !important;

  @apply text-primary text-lg font-medium rounded-full;
}

.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  margin: 0;
}

.error {
  line-height: 19px;

  @apply absolute right-0 text-error text-md font-medium cursor-pointer;
}

.errorContainer {
  @apply border-error shadow-error;
}

.icon {
  @apply mr-4 w-6 h-6;
}

.hidden {
  @apply invisible;
}

.currencyName {
  @apply pr-8 text-left text-gray-600 pointer-events-none;
}
