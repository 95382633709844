.menuContainer {
  min-height: 100vh;
  padding-top: 130px;
  z-index: 5;
  transition: transform 300ms ease-in-out;
  transform: translateY(calc(-130vh));
  overflow: auto;

  @apply fixed bottom-0 top-0 flex flex-col items-center justify-between w-full max-w-lg bg-white;
}

.menuItemsContainer {
  @apply flex flex-col h-full;
}

.menuOn {
  z-index: 5;
  transition: transform 300ms ease-in-out;
  transform: translateY(0);
}

.menuItem {
  height: 30px;
  line-height: 22px;
  margin-top: 12px;
  margin-bottom: 12px;

  @apply w-full text-center text-xl font-semibold cursor-pointer;
}

.versionText {
  font-size: 12px;
  line-height: 15px;

  @apply py-8;
}
