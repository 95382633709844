.buttonsContainer {
  box-shadow: 0 -5px 16px #00297b29;
  border-radius: 16px 16px 0 0;

  @apply flex flex-col fixed bottom-0 w-full max-w-lg items-center px-8 pb-2 pt-6 bg-white;
}

.textLink {
  @apply underline text-sm mt-4 mb-8 md:mb-12 w-full;
}
