.container {
  @apply flex flex-row mb-4 cursor-pointer;
}

.checkboxContainer {
  @apply mr-3 mt-0.5;
}

.checkboxIcon {
  @apply w-6 h-6;
}

.input {
  @apply hidden;
}
