.outerContainer {
  @apply flex flex-col my-4 p-4 w-full rounded-3xl shadow-custom;
}

.container {
  @apply flex flex-row items-center;
}

.image {
  min-height: 80px;
  min-width: 80px;

  @apply mr-4 w-16 h-16 bg-cover rounded-full;
}

.churchContainer {
  min-height: 80px;
  min-width: 80px;
  background-color: #f2f4f8;

  @apply flex items-center justify-center mr-4 w-16 h-16 rounded-full;
}

.textContainer {
  overflow-wrap: anywhere;

  @apply flex flex-col justify-center;
}

.addressText {
  line-height: 18px;

  @apply text-sm font-medium;
}

.nameText {
  font-size: 17px;
  line-height: 22px;

  @apply mb-2 font-bold;
}
