.container {
  @apply flex items-center w-full;
}

.text {
  line-height: 16px;

  @apply text-sm font-medium;
}

.error {
  @apply text-error;
}

.icon {
  @apply mr-3 mt-0.5 w-6 h-6;
}
