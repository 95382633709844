.outerContainer {
  overflow-wrap: anywhere;

  @apply flex flex-col items-center mt-4 px-6 w-full rounded-3xl shadow-custom;
}

.separator {
  opacity: 0.57;

  @apply my-4 w-full h-px bg-primary rounded-2xl;
}

.nameText {
  line-height: 18px;

  @apply mt-6 text-center text-md font-bold;
}

.subHeadline {
  line-height: 18px;

  @apply text-primary text-md font-semibold;
}

.amountText {
  line-height: 22px;

  @apply mb-6 mt-4 text-xl font-bold;
}

.buttonsContainer {
  @apply flex flex-col items-center px-2 py-4 w-full;
}

.buttonsContainerHeadline {
  line-height: 18px;

  @apply my-6 text-md font-semibold;
}

.button {
  height: 72px;

  @apply flex flex-row items-center justify-center mb-4 w-full border border-primary rounded-full cursor-pointer;
}

.buttonText {
  line-height: 19px;
  margin-left: 20px;

  @apply text-primary text-lg font-semibold;
}

.iconStyle {
  height: 40px;
  width: 40px;

  @apply rounded-full;
}
