.screenContainer {
  min-width: 320px;
  padding-top: 80px;

  @apply relative items-center justify-center w-full max-w-lg;
}

.contentContainer {
  @apply flex flex-col items-center mt-4 px-4 w-full;
}

.icon {
  @apply cursor-pointer;
}
